import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import review from "../Assets/review.png";
import "./Quiz.css";
import { useGlobalContext } from "../GlobalContext";

function QuizLanding(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
            <h1>Review</h1>
            <p>Review what you learnt by answering the following questions.</p>
            <img
              src={review}
              alt="Review Image"
              style={{ width: "95%", height: "auto" }}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
            <h1>Revisión</h1>
            <p>Repase lo que aprendió y responda las siguientes preguntas.</p>
            <img
              src={review}
              alt="Review Image"
              style={{ width: "95%", height: "auto" }}
            />
          </div>
        </>
      )}

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function QuizAnswerCorrect() {
  return (
    <div className="quiz-answer-correct">
      <p>✅&nbsp;&nbsp;Correct!</p>
    </div>
  );
}

export { QuizLanding, QuizAnswerCorrect };
