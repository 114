import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import {
  BottomButtonContainer,
  TopButtonContainer,
} from "../Components/ButtonContainer";
import { useGlobalContext } from "../GlobalContext";
import "./EducationPage.css";

function EducationPage(props: { next: any; setSlideIndex?: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div
      id=""
      className="landing-container"
      style={{ alignItems: "flex-start" }}
    >
      <TopButtonContainer nextButtonAction={() => {}} />

      <div
        style={{
          marginLeft: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            marginRight: "9%",
          }}
        >
          {!isSpanish ? (
            <>
              <h1>Five Path Attributes</h1>
              <p>
                Description for each five path attributes will be shown,
                followed by a short quiz.
              </p>
            </>
          ) : (
            <>
              <h1>Los Cinco Atributos Del Camino</h1>
              <p>
                Una descripción para cada cinco atributos del camino se
                mostrará, seguido de una prueba corta.
              </p>
            </>
          )}
        </div>

        {!isSpanish ? (
          <>
            <div className="list-section">
              <div className="list-item">
                <div className="circle">1</div>
                <h2>Walk time</h2>
              </div>
              <div className="list-item">
                <div className="circle">2</div>
                <h2>Sidewalk condition</h2>
              </div>
              <div className="list-item">
                <div className="circle">3</div>
                <h2>Crime safety</h2>
              </div>
              <div className="list-item">
                <div className="circle">4</div>
                <h2>Traffic Safety</h2>
              </div>
              <div className="list-item">
                <div className="circle">5</div>
                <h2>Thermal comfort</h2>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="list-section">
              <div className="list-item">
                <div className="circle">1</div>
                <h2>Tiempo de Caminata</h2>
              </div>
              <div className="list-item">
                <div className="circle">2</div>
                <h2>La condición de la Acera</h2>
              </div>
              <div className="list-item">
                <div className="circle">3</div>
                <h2>Seguridad Contra el Crimen</h2>
              </div>
              <div className="list-item">
                <div className="circle">4</div>
                <h2>Seguridad del Tráfico</h2>
              </div>
              <div className="list-item">
                <div className="circle">5</div>
                <h2>La comodidad térmica</h2>
              </div>
            </div>
          </>
        )}
      </div>

      <BottomButtonContainer
        backButtonAction={props.back}
        nextButtonAction={props.next}
      />
    </div>
  );
}

export default EducationPage;
