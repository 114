import { createContext } from 'react';
import './App.css';
import LandingPage from './Landing/LandingPage';

// Overview
import OverviewPage from './Landing/SurveyOverview';
import RegistrationPage from './Landing/RegistrationPage';

// Education
import EducationPage from './Education/EducationPage';
import WalkTime from './Education/WalkTime';
import SidewalkCondition, {
    SidewalkConditionBad, SidewalkConditionModerate,
    SidewalkConditionGood, SidewalkConditionSummary
} from './Education/SidewalkCondition';
import CrimeSafety, {
    CrimeSafetyLow, CrimeSafetyModerate,
    CrimeSafetyHigh, CrimeSafetySummary
} from './Education/CrimeSafety';
import TrafficSafety, {
    TrafficSafetyLow, TrafficSafetyModerate,
    TrafficSafetyHigh, TrafficSafetySummary
} from './Education/TrafficSafety';
import ThermalComfort, {
    ThermalComfortHot, ThermalComfortWarm,
    ThermalComfortNeutral, ThermalComfortSummary
} from './Education/ThermalComfort';

// Quiz
import { QuizLanding } from './Quiz/Quiz';
import Quiz1 from './Quiz/Quiz1';
import Quiz2 from './Quiz/Quiz2';
import Quiz3 from './Quiz/Quiz3';
import Quiz4 from './Quiz/Quiz4';
import QuizResults from './Quiz/QuizResults';

// Survey
import { SurveyPurposefulWalks, SurveyRecreationalWalks, SurveySelection } from './Survey/Survey';
import SurveyQuestion from './Survey/SurveyQuestion';
import { tables_v1, tables_v2, tables_v3, tables_v4 } from './Tables';
import SurveyComplete from './Quiz/SurveyComplete';

import { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';
import axios from "axios";

import { GlobalProvider } from './GlobalContext';

// Global context
export const UserContext = createContext(null);

function App() {
    const [quizScore, setQuizScore] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);
    const [purposefulSurveyVersion, setPurposefulSurveyVersion] = useState(0);
    const [recreationalSurveyVersion, setRecreationalSurveyVersion] = useState(0);
    const [response, setResponse] = useState([]);
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [ip, setIP] = useState('');

    const NUM_QUESTIONS = 30;

    var sliderSettings = {
        swipe: false,
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        speed: 400,
        beforeChange: (current, next) => setSlideIndex(next)
    };

    const submitSurvey = async () => {
        // TODO: Fix these

        // let docName = (name + "_" + birthdate).replaceAll("/", "-");
        // const docRef = await setDoc(doc(db, "survey_responses", docName), {
        //     name: name,
        //     gender: gender,
        //     birthdate: birthdate,
        //     survey_version: surveyVersion,
        //     responses: response,
        //     time: Date.now(),
        //     ip_address: ip
        // })
    }

    // Update IP address on launch
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setIP(res.data.ip);
    };
    useEffect(() => {
        getData();
    }, []);


    const nextPage = () => {
        // TODO: Fix these

        // if (slideIndex === 57) { // submit
        //     submitSurvey();
        // }

        setSlideIndex(slideIndex + 1);
    }

    const backPage = () => {
        setSlideIndex(slideIndex - 1);
    }

    const incrementQuizScore = () => {
        setQuizScore(quizScore + 1);
    }
    const toRetakeQuiz = () => {
        nextPage();
        setQuizScore(0);
    };
    const refresh = () => {
        window.location.reload();
    }

    const SURVEY_TYPES = {
        PURPOSEFUL: 0,
        RECREATIONAL: 1,
    }

    const getTable = (survey_type) => {
        if (survey_type === SURVEY_TYPES.PURPOSEFUL) {
            if (purposefulSurveyVersion === 1) {
                return tables_v1;
            } else if (purposefulSurveyVersion === 2) {
                return tables_v2;
            }
            else if (purposefulSurveyVersion === 3) {
                return tables_v3;
            }
            else {
                return tables_v4;
            }
        }
        else {
            // TODO: Fix what's returned here
            if (recreationalSurveyVersion === 1) {
                return tables_v1;
            } else if (purposefulSurveyVersion === 2) {
                return tables_v2;
            }
            else if (purposefulSurveyVersion === 3) {
                return tables_v3;
            }
            else {
                return tables_v4;
            }

        }

    }

    const setQuestionResponse = (questionNumber, selection) => {
        var newResponse = response;
        newResponse[questionNumber - 1] = selection;
        setResponse(newResponse);
        // console.log(newResponse);
    }

    const setRegistrationResponse = (name, gender, birthdate) => {
        setName(name);
        setGender(gender);
        setBirthdate(birthdate);
    }

    // Show a warning when the user tries to leave the page
    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //       event.preventDefault();
    //       event.returnValue = '';
    //     };
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     return () => {
    //       window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    //   }, []);

    useEffect(() => {
        sliderRef.slickGoTo(slideIndex);
    }, [slideIndex]);


    var sliderRef = useRef(null);

    return (
        <GlobalProvider>
            <div id='app'>
                <Slider {...sliderSettings} ref={slider => { sliderRef = slider; }}
                >
                    {/* [0-2] Landing Pages */}
                    <LandingPage next={nextPage} setSlideIndex={setSlideIndex} />
                    <OverviewPage next={nextPage} back={backPage} />
                    <RegistrationPage next={nextPage} setResponse={setRegistrationResponse} back={backPage} />

                    {/* [3] Education Page: intro */}
                    <EducationPage next={nextPage} setSlideIndex={setSlideIndex} back={backPage} />
                    {/* [4] 1. Walk Time */}
                    <WalkTime next={nextPage} back={backPage} />

                    {/* [5-9] 2. Sidewalk Condition */}
                    <SidewalkCondition next={nextPage} back={backPage} />
                    <SidewalkConditionBad next={nextPage} back={backPage} />
                    <SidewalkConditionModerate next={nextPage} back={backPage} />
                    <SidewalkConditionGood next={nextPage} back={backPage} />
                    <SidewalkConditionSummary next={nextPage} back={backPage} />

                    {/* [10-14] 3. Crime Safety */}
                    <CrimeSafety next={nextPage} back={backPage} />
                    <CrimeSafetyLow next={nextPage} back={backPage} />
                    <CrimeSafetyModerate next={nextPage} back={backPage} />
                    <CrimeSafetyHigh next={nextPage} back={backPage} />
                    <CrimeSafetySummary next={nextPage} back={backPage} />

                    {/* [15-19] 4. Traffic Safety */}
                    <TrafficSafety next={nextPage} back={backPage} />
                    <TrafficSafetyLow next={nextPage} back={backPage} />
                    <TrafficSafetyModerate next={nextPage} back={backPage} />
                    <TrafficSafetyHigh next={nextPage} back={backPage} />
                    <TrafficSafetySummary next={nextPage} back={backPage} />

                    {/* [20-24] 5. Thermal Comfort */}
                    <ThermalComfort next={nextPage} back={backPage} />
                    <ThermalComfortHot next={nextPage} back={backPage} />
                    <ThermalComfortWarm next={nextPage} back={backPage} />
                    <ThermalComfortNeutral next={nextPage} back={backPage} />
                    <ThermalComfortSummary next={nextPage} back={backPage} />

                    {/* [25] Quiz Landing */}
                    <QuizLanding next={nextPage} back={backPage} />

                    {/* [26-29] Quiz 1-5 */}
                    <Quiz1 next={nextPage} incrementScore={incrementQuizScore} />
                    <Quiz2 next={nextPage} incrementScore={incrementQuizScore} />
                    <Quiz3 next={nextPage} incrementScore={incrementQuizScore} />
                    <Quiz4 next={nextPage} incrementScore={incrementQuizScore} />

                    {/* [30] Results */}
                    <QuizResults pass={() => setSlideIndex(31)} fail={toRetakeQuiz} quizScore={quizScore} retake={false} />

                    {/* [31-32] Purposeful Walks Survey */}
                    <SurveyPurposefulWalks next={nextPage} />
                    <SurveySelection next={nextPage} back={backPage} surveyVersion={purposefulSurveyVersion} setSurveyVersion={setPurposefulSurveyVersion} />

                    {/* [33-62] Purposeful Walk Questions */}
                    {
                        Array(NUM_QUESTIONS).fill().map((_, i) => {
                            return <SurveyQuestion key={i} surveyType={SURVEY_TYPES.PURPOSEFUL} questionNumber={i + 1} totalQuestions={NUM_QUESTIONS} route1={getTable(SURVEY_TYPES.PURPOSEFUL)[i][0]} route2={getTable(SURVEY_TYPES.PURPOSEFUL)[i][1]} next={nextPage} back={backPage} setResponse={setQuestionResponse} backDisabled={i === 0} />
                        })
                    }

                    {/* [63-64] Recreational Walks Survey */}
                    <SurveyRecreationalWalks next={nextPage} />
                    <SurveySelection next={nextPage} back={backPage} surveyVersion={recreationalSurveyVersion} setSurveyVersion={setRecreationalSurveyVersion} />

                    {/* [65-94] Recreational Walk Questions */}
                    {
                        Array(NUM_QUESTIONS).fill().map((_, i) => {
                            return <SurveyQuestion key={i} surveyType={SURVEY_TYPES.RECREATIONAL} questionNumber={i + 1} totalQuestions={NUM_QUESTIONS} route1={getTable(SURVEY_TYPES.RECREATIONAL)[i][0]} route2={getTable(SURVEY_TYPES.RECREATIONAL)[i][1]} next={nextPage} back={backPage} setResponse={setQuestionResponse} backDisabled={i === 0} />
                        })
                    }

                    {/* [95] Survey Complete */}
                    <SurveyComplete />
                </Slider>
            </div>
        </GlobalProvider>
    )
}

export default App;
