import { useGlobalContext } from "../GlobalContext";
import "./Quiz.css";

function SurveyComplete() {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="quiz-results">
      <h3>✅</h3>
      {!isSpanish ? (
        <>
          {" "}
          <h3>Survey Complete!</h3>
          <h3>Thank you for your participation!</h3>
          <br />
        </>
      ) : (
        <>
          <h3>Encuesta Completa!</h3>
          <h3>¡Gracias por su participación!</h3>
          <br />
        </>
      )}
    </div>
  );
}

export default SurveyComplete;
