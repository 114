import { useState } from "react";

import landing_1 from "../Assets/landing_1.png";
import landing_2 from "../Assets/landing_2.png";
import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import { useGlobalContext } from "../GlobalContext";

// TODO: Simplify this file styling is very odd

function OverviewPage(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ width: "80%", marginLeft: "2%", textAlign: "left" }}>
        {!isSpanish ? (
          <>
            <h1>Survey Overview</h1>
            <p>
              The objective of this survey is to understand people's preferences
              when choosing a walking route in two different cases:
            </p>
          </>
        ) : (
          <>
            <h1>Resumen de la Encuesta</h1>
            <p>
              El objetivo de esta encuesta es entender las preferencias de el
              publico (usted) cuando elige una ruta para caminar en casos
              diferentes, por ejemplo:
            </p>
          </>
        )}
      </div>

      <div style={{ width: "80%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            marginTop: "-40px",
          }}
        >
          <div
            style={{
              flex: 1,
              margin: "20px",
              marginBottom: "3%",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "70px",
                marginRight: "10px",
                fontWeight: "bold",
              }}
            >
              1
            </div>
            <p style={{ textAlign: "left" }}>
              {!isSpanish ? (
                <>
                  Walking as a{" "}
                  <u>
                    <strong>transportation method</strong>
                  </u>{" "}
                  (i.e., to get to places)
                </>
              ) : (
                <>
                  <u>
                    <strong>Caminar como método de transporte"</strong>
                  </u>{" "}
                  (por ejemplo, caminar al mercado para comprar para ir a
                  comprar algun producto en especifico)
                </>
              )}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              margin: "20px",
              marginBottom: "3%",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "70px",
                marginRight: "10px",
                fontWeight: "bold",
              }}
            >
              2
            </div>
            <p style={{ textAlign: "left" }}>
              {!isSpanish ? (
                <>
                  Walking for{" "}
                  <u>
                    <strong>recreation, sport, exercise, or leisure</strong>
                  </u>
                </>
              ) : (
                <>
                  <u>
                    <strong>Los Paseos recreativos y de diversion </strong>
                  </u>
                  (por ejemplo, caminar alrededor de un parque para hacer
                  ejercicio)
                </>
              )}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            marginTop: "-55px",
          }}
        >
          <div style={{ flex: 1, margin: "20px", textAlign: "center" }}>
            <img
              src={landing_1}
              alt="Purposeful Walks"
              style={{ width: "95%", height: "auto", borderRadius: "3%" }}
            />
          </div>
          <div style={{ flex: 1, margin: "20px", textAlign: "center" }}>
            <img
              src={landing_2}
              alt="Recreational Walks"
              style={{ width: "95%", height: "auto", borderRadius: "3%" }}
            />
          </div>
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

export default OverviewPage;
