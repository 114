// ToggleContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ToggleContextProps {
  isToggled: boolean;
  toggle: () => void;
}

const ToggleContext = createContext<ToggleContextProps | undefined>(undefined);

export const useToggle = (): ToggleContextProps => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error('useToggle must be used within a ToggleProvider');
  }
  return context;
};

interface ToggleProviderProps {
  children: ReactNode;
}

export const ToggleProvider: React.FC<ToggleProviderProps> = ({ children }) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const toggle = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <ToggleContext.Provider value={{ isToggled, toggle }}>
      {children}
    </ToggleContext.Provider>
  );
};
