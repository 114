/**
 * Adding new tables:
 * - Copy the sampleTable object and paste it below.
 * - Change the values of the object to the new table's data.
 * 
 * Then, return to App.tsx for further instructions.
 */

import LevelTag from "./Components/LevelTag";

class RouteEntry {
    public name: string;
    public walkTime: number;
    public sidewalkQuality: number;
    public crimeSafety: number;
    public trafficSafety: number;
    public thermalComfort: number;

    constructor(name: string, walkTime: number, sidewalkQuality: number, crimeSafety: number, trafficSafety: number, thermalComfort: number) {
        this.name = name;
        this.walkTime = walkTime;
        this.sidewalkQuality = sidewalkQuality;
        this.crimeSafety = crimeSafety;
        this.trafficSafety = trafficSafety;
        this.thermalComfort = thermalComfort;
    }

    getWalkTimeString(): string {
        return String(this.walkTime);
    }

    getSidewalkQualityString(isSpanish: boolean): string {
        const en = ["Invalid", "Bad", "Moderate", "Good"];
        const es = ["Inválido", "Malo", "Moderado", "Bueno"];
        if (!isSpanish) return en[this.sidewalkQuality];
        else return es[this.sidewalkQuality]
    }

    getCrimeSafetyString(isSpanish: boolean): string {
        const en = ["Invalid", "Low safe", "Moderate", "High safe"];
        const es = ["Inválido", "Menos seguro", "Moderado", "Muy seguro"];
        if (!isSpanish) return en[this.crimeSafety];
        else return es[this.crimeSafety];
    }

    getTrafficSafetyString(isSpanish: boolean): string {
        const en = ["Invalid", "Low safe", "Moderate", "High safe"];
        const es = ["Inválido", "Menos seguro", "Moderado", "Muy seguro"];
        if (!isSpanish) return en[this.trafficSafety];
        else return es[this.trafficSafety];
    }

    getThermalComfortString(isSpanish: boolean): string {
        const en = ["Invalid", "Hot", "Warm", "Neutral"];
        const es = ["Inválido", "Caliente", "Templado", "Neutral"];
        if (!isSpanish) return en[this.thermalComfort];
        else return es[this.thermalComfort];
    }

}

const tables_v1: RouteEntry[][] = [
    [new RouteEntry("Path 1", 10, 1, 3, 1, 3), new RouteEntry("Path 2", 12, 2, 1, 1, 1)],
    [new RouteEntry("Path 3", 10, 3, 1, 1, 3), new RouteEntry("Path 4", 12, 2, 1, 1, 3)],
    [new RouteEntry("Path 5", 10, 3, 3, 3, 1), new RouteEntry("Path 6", 12, 3, 1, 1, 3)],
    [new RouteEntry("Path 7", 10, 2, 3, 1, 2), new RouteEntry("Path 8", 12, 1, 1, 3, 1)],
    [new RouteEntry("Path 9", 29, 1, 3, 3, 3), new RouteEntry("Path 10", 8, 2, 1, 1, 2)],
    [new RouteEntry("Path 11", 19, 2, 3, 3, 1), new RouteEntry("Path 12", 22, 3, 3, 1, 1)],
    [new RouteEntry("Path 13", 18, 2, 3, 1, 3), new RouteEntry("Path 14", 26, 1, 1, 1, 1)],
    [new RouteEntry("Path 15", 4, 1, 3, 1, 3), new RouteEntry("Path 16", 7, 1, 1, 1, 2)],
    [new RouteEntry("Path 17", 21, 1, 1, 3, 1), new RouteEntry("Path 18", 24, 3, 3, 3, 2)],
    [new RouteEntry("Path 19", 7, 2, 3, 3, 1), new RouteEntry("Path 20", 12, 1, 3, 3, 1)],
    [new RouteEntry("Path 21", 27, 2, 1, 3, 2), new RouteEntry("Path 22", 25, 3, 3, 3, 3)],
    [new RouteEntry("Path 23", 16, 2, 1, 3, 3), new RouteEntry("Path 24", 20, 1, 3, 1, 2)],
    [new RouteEntry("Path 25", 1, 3, 3, 1, 1), new RouteEntry("Path 26", 29, 2, 3, 1, 2)],
    [new RouteEntry("Path 27", 2, 3, 1, 3, 2), new RouteEntry("Path 28", 1, 1, 1, 1, 3)],
    [new RouteEntry("Path 29", 14, 2, 3, 3, 1), new RouteEntry("Path 30", 11, 1, 3, 1, 2)],
    [new RouteEntry("Path 31", 28, 3, 1, 3, 2), new RouteEntry("Path 32", 30, 1, 1, 3, 1)], 
    [new RouteEntry("Path 33", 6, 3, 3, 3, 2), new RouteEntry("Path 34", 15, 3, 1, 1, 3)], 
    [new RouteEntry("Path 35", 3, 3, 1, 3, 3), new RouteEntry("Path 36", 23, 2, 1, 3, 2)],
    //
    [new RouteEntry("Path 37", 24, 1, 3, 1, 3), new RouteEntry("Path 38", 2, 2, 1, 1, 1)],
    [new RouteEntry("Path 39", 10, 3, 1, 1, 3), new RouteEntry("Path 40", 17, 2, 1, 1, 3)],
    [new RouteEntry("Path 41", 17, 3, 3, 3, 1), new RouteEntry("Path 42", 5, 3, 1, 1, 3)],
    [new RouteEntry("Path 43", 9, 2, 3, 1, 2), new RouteEntry("Path 44", 13, 1, 1, 3, 1)],
    [new RouteEntry("Path 45", 29, 1, 3, 3, 3), new RouteEntry("Path 46", 8, 2, 1, 1, 2)],
    [new RouteEntry("Path 47", 19, 2, 3, 3, 1), new RouteEntry("Path 48", 22, 3, 3, 1, 1)],
    [new RouteEntry("Path 49", 18, 2, 3, 1, 3), new RouteEntry("Path 50", 26, 1, 1, 1, 1)],
    [new RouteEntry("Path 51", 4, 1, 3, 1, 3), new RouteEntry("Path 52", 7, 1, 1, 1, 2)],
    [new RouteEntry("Path 53", 21, 1, 1, 3, 1), new RouteEntry("Path 54", 24, 3, 3, 3, 2)],
    [new RouteEntry("Path 55", 7, 2, 3, 3, 1), new RouteEntry("Path 56", 12, 1, 3, 3, 1)],
    [new RouteEntry("Path 57", 27, 2, 1, 3, 2), new RouteEntry("Path 58", 25, 3, 3, 3, 3)],
    [new RouteEntry("Path 59", 16, 2, 1, 3, 3), new RouteEntry("Path 60", 20, 1, 3, 1, 2)],
];

const tables_v2: RouteEntry[][] = [
    [new RouteEntry("Path 1", 10, 2, 3, 1, 2), new RouteEntry("Path 2", 12, 3, 3, 3, 3)],
    [new RouteEntry("Path 3", 13, 2, 3, 1, 3), new RouteEntry("Path 4", 18, 3, 1, 3, 2)],
    [new RouteEntry("Path 5", 8, 1, 3, 3, 1), new RouteEntry("Path 6", 14, 3, 1, 1, 3)],
    [new RouteEntry("Path 7", 20, 2, 1, 1, 1), new RouteEntry("Path 8", 14, 1, 3, 1, 2)],
    [new RouteEntry("Path 9", 12, 3, 1, 1, 2), new RouteEntry("Path 10", 9, 1, 1, 3, 3)],
    [new RouteEntry("Path 11", 15, 2, 3, 3, 1), new RouteEntry("Path 12", 19, 1, 1, 1, 2)],
    [new RouteEntry("Path 13", 6, 1, 1, 1, 1), new RouteEntry("Path 14", 6, 2, 1, 1, 3)],
    [new RouteEntry("Path 15", 30, 2, 3, 3, 3), new RouteEntry("Path 16", 22, 1, 1, 3, 3)],
    [new RouteEntry("Path 17", 5, 1, 3, 3, 2), new RouteEntry("Path 18", 20, 3, 3, 3, 3)],
    [new RouteEntry("Path 19", 23, 3, 3, 1, 1), new RouteEntry("Path 20", 29, 3, 1, 1, 1)],
    [new RouteEntry("Path 21", 25, 2, 1, 3, 2), new RouteEntry("Path 22", 10, 1, 3, 3, 2)],
    [new RouteEntry("Path 23", 3, 2, 3, 1, 1), new RouteEntry("Path 24", 1, 2, 1, 3, 2)], 
    [new RouteEntry("Path 25", 27, 3, 3, 1, 1), new RouteEntry("Path 26", 16, 3, 3, 1, 2)], 
    [new RouteEntry("Path 27", 11, 3, 1, 3, 1), new RouteEntry("Path 28", 4, 3, 1, 3, 1)], 
    [new RouteEntry("Path 29", 17, 1, 1, 3, 2), new RouteEntry("Path 30", 25, 1, 1, 1, 1)], 
    [new RouteEntry("Path 31", 26, 2, 3, 3, 3), new RouteEntry("Path 32", 30, 3, 3, 1, 2)], 
    [new RouteEntry("Path 33", 28, 1, 3, 1, 3), new RouteEntry("Path 34", 2, 1, 3, 3, 3)], 
    [new RouteEntry("Path 35", 12, 2, 1, 3, 3), new RouteEntry("Path 36", 24, 2, 1, 1, 1)],
    //
    [new RouteEntry("Path 37", 24, 1, 3, 1, 3), new RouteEntry("Path 38", 2, 2, 1, 1, 1)],
    [new RouteEntry("Path 39", 10, 3, 1, 1, 3), new RouteEntry("Path 40", 17, 2, 1, 1, 3)],
    [new RouteEntry("Path 41", 17, 3, 3, 3, 1), new RouteEntry("Path 42", 5, 3, 1, 1, 3)],
    [new RouteEntry("Path 43", 9, 2, 3, 1, 2), new RouteEntry("Path 44", 13, 1, 1, 3, 1)],
    [new RouteEntry("Path 45", 29, 1, 3, 3, 3), new RouteEntry("Path 46", 8, 2, 1, 1, 2)],
    [new RouteEntry("Path 47", 19, 2, 3, 3, 1), new RouteEntry("Path 48", 22, 3, 3, 1, 1)],
    [new RouteEntry("Path 49", 18, 2, 3, 1, 3), new RouteEntry("Path 50", 26, 1, 1, 1, 1)],
    [new RouteEntry("Path 51", 4, 1, 3, 1, 3), new RouteEntry("Path 52", 7, 1, 1, 1, 2)],
    [new RouteEntry("Path 53", 21, 1, 1, 3, 1), new RouteEntry("Path 54", 24, 3, 3, 3, 2)],
    [new RouteEntry("Path 55", 7, 2, 3, 3, 1), new RouteEntry("Path 56", 12, 1, 3, 3, 1)],
    [new RouteEntry("Path 57", 27, 2, 1, 3, 2), new RouteEntry("Path 58", 25, 3, 3, 3, 3)],
    [new RouteEntry("Path 59", 16, 2, 1, 3, 3), new RouteEntry("Path 60", 20, 1, 3, 1, 2)],
];

const tables_v3: RouteEntry[][] = [
    [new RouteEntry("Path 1", 10, 2, 3, 1, 2), new RouteEntry("Path 2", 12, 3, 3, 3, 3)],
    [new RouteEntry("Path 3", 10, 2, 3, 1, 3), new RouteEntry("Path 4", 12, 3, 1, 3, 2)],
    [new RouteEntry("Path 5", 8, 1, 3, 3, 1), new RouteEntry("Path 6", 14, 3, 1, 1, 3)],
    [new RouteEntry("Path 7", 20, 2, 1, 1, 1), new RouteEntry("Path 8", 14, 1, 3, 1, 2)],
    [new RouteEntry("Path 9", 12, 3, 1, 1, 2), new RouteEntry("Path 10", 9, 1, 1, 3, 3)],
    [new RouteEntry("Path 11", 15, 2, 3, 3, 1), new RouteEntry("Path 12", 19, 1, 1, 1, 2)],
    [new RouteEntry("Path 13", 6, 1, 1, 1, 1), new RouteEntry("Path 14", 6, 2, 1, 1, 3)],
    [new RouteEntry("Path 15", 30, 2, 3, 3, 3), new RouteEntry("Path 16", 22, 1, 1, 3, 3)],
    [new RouteEntry("Path 17", 5, 1, 3, 3, 2), new RouteEntry("Path 18", 20, 3, 3, 3, 3)],
    [new RouteEntry("Path 19", 23, 3, 3, 1, 1), new RouteEntry("Path 20", 29, 3, 1, 1, 1)],
    [new RouteEntry("Path 21", 25, 2, 1, 3, 2), new RouteEntry("Path 22", 10, 1, 3, 3, 2)],
    [new RouteEntry("Path 23", 3, 2, 3, 1, 1), new RouteEntry("Path 24", 1, 2, 1, 3, 2)], 
    [new RouteEntry("Path 25", 27, 3, 3, 1, 1), new RouteEntry("Path 26", 16, 3, 3, 1, 2)], 
    [new RouteEntry("Path 27", 11, 3, 1, 3, 1), new RouteEntry("Path 28", 4, 3, 1, 3, 1)], 
    [new RouteEntry("Path 29", 17, 1, 1, 3, 2), new RouteEntry("Path 30", 25, 1, 1, 1, 1)], 
    [new RouteEntry("Path 31", 26, 2, 3, 3, 3), new RouteEntry("Path 32", 30, 3, 3, 1, 2)], 
    [new RouteEntry("Path 33", 28, 1, 3, 1, 3), new RouteEntry("Path 34", 2, 1, 3, 3, 3)], 
    [new RouteEntry("Path 35", 12, 2, 1, 3, 3), new RouteEntry("Path 36", 24, 2, 1, 1, 1)],
    //
    [new RouteEntry("Path 37", 24, 1, 3, 1, 3), new RouteEntry("Path 38", 2, 2, 1, 1, 1)],
    [new RouteEntry("Path 39", 10, 3, 1, 1, 3), new RouteEntry("Path 40", 17, 2, 1, 1, 3)],
    [new RouteEntry("Path 41", 17, 3, 3, 3, 1), new RouteEntry("Path 42", 5, 3, 1, 1, 3)],
    [new RouteEntry("Path 43", 9, 2, 3, 1, 2), new RouteEntry("Path 44", 13, 1, 1, 3, 1)],
    [new RouteEntry("Path 45", 29, 1, 3, 3, 3), new RouteEntry("Path 46", 8, 2, 1, 1, 2)],
    [new RouteEntry("Path 47", 19, 2, 3, 3, 1), new RouteEntry("Path 48", 22, 3, 3, 1, 1)],
    [new RouteEntry("Path 49", 18, 2, 3, 1, 3), new RouteEntry("Path 50", 26, 1, 1, 1, 1)],
    [new RouteEntry("Path 51", 4, 1, 3, 1, 3), new RouteEntry("Path 52", 7, 1, 1, 1, 2)],
    [new RouteEntry("Path 53", 21, 1, 1, 3, 1), new RouteEntry("Path 54", 24, 3, 3, 3, 2)],
    [new RouteEntry("Path 55", 7, 2, 3, 3, 1), new RouteEntry("Path 56", 12, 1, 3, 3, 1)],
    [new RouteEntry("Path 57", 27, 2, 1, 3, 2), new RouteEntry("Path 58", 25, 3, 3, 3, 3)],
    [new RouteEntry("Path 59", 16, 2, 1, 3, 3), new RouteEntry("Path 60", 20, 1, 3, 1, 2)],
];

const tables_v4: RouteEntry[][] = [
    [new RouteEntry("Path 1", 10, 2, 3, 1, 2), new RouteEntry("Path 2", 12, 3, 3, 3, 3)],
    [new RouteEntry("Path 3", 10, 2, 3, 1, 3), new RouteEntry("Path 4", 12, 3, 1, 3, 2)],
    [new RouteEntry("Path 5", 10, 1, 3, 3, 1), new RouteEntry("Path 6", 12, 3, 1, 1, 3)],
    [new RouteEntry("Path 7", 10, 2, 1, 1, 1), new RouteEntry("Path 8", 12, 1, 3, 1, 2)],
    [new RouteEntry("Path 9", 12, 3, 1, 1, 2), new RouteEntry("Path 10", 9, 1, 1, 3, 3)],
    [new RouteEntry("Path 11", 15, 2, 3, 3, 1), new RouteEntry("Path 12", 19, 1, 1, 1, 2)],
    [new RouteEntry("Path 13", 6, 1, 1, 1, 1), new RouteEntry("Path 14", 6, 2, 1, 1, 3)],
    [new RouteEntry("Path 15", 30, 2, 3, 3, 3), new RouteEntry("Path 16", 22, 1, 1, 3, 3)],
    [new RouteEntry("Path 17", 5, 1, 3, 3, 2), new RouteEntry("Path 18", 20, 3, 3, 3, 3)],
    [new RouteEntry("Path 19", 23, 3, 3, 1, 1), new RouteEntry("Path 20", 29, 3, 1, 1, 1)],
    [new RouteEntry("Path 21", 25, 2, 1, 3, 2), new RouteEntry("Path 22", 10, 1, 3, 3, 2)],
    [new RouteEntry("Path 23", 3, 2, 3, 1, 1), new RouteEntry("Path 24", 1, 2, 1, 3, 2)],
    [new RouteEntry("Path 25", 27, 3, 3, 1, 1), new RouteEntry("Path 26", 16, 3, 3, 1, 2)], 
    [new RouteEntry("Path 27", 11, 3, 1, 3, 1), new RouteEntry("Path 28", 4, 3, 1, 3, 1)], 
    [new RouteEntry("Path 29", 17, 1, 1, 3, 2), new RouteEntry("Path 30", 25, 1, 1, 1, 1)], 
    [new RouteEntry("Path 31", 26, 2, 3, 3, 3), new RouteEntry("Path 32", 30, 3, 3, 1, 2)], 
    [new RouteEntry("Path 33", 28, 1, 3, 1, 3), new RouteEntry("Path 34", 2, 1, 3, 3, 3)], 
    [new RouteEntry("Path 35", 12, 2, 1, 3, 3), new RouteEntry("Path 36", 24, 2, 1, 1, 1)], 
    //
    [new RouteEntry("Path 37", 24, 1, 3, 1, 3), new RouteEntry("Path 38", 2, 2, 1, 1, 1)],
    [new RouteEntry("Path 39", 10, 3, 1, 1, 3), new RouteEntry("Path 40", 17, 2, 1, 1, 3)],
    [new RouteEntry("Path 41", 17, 3, 3, 3, 1), new RouteEntry("Path 42", 5, 3, 1, 1, 3)],
    [new RouteEntry("Path 43", 9, 2, 3, 1, 2), new RouteEntry("Path 44", 13, 1, 1, 3, 1)],
    [new RouteEntry("Path 45", 29, 1, 3, 3, 3), new RouteEntry("Path 46", 8, 2, 1, 1, 2)],
    [new RouteEntry("Path 47", 19, 2, 3, 3, 1), new RouteEntry("Path 48", 22, 3, 3, 1, 1)],
    [new RouteEntry("Path 49", 18, 2, 3, 1, 3), new RouteEntry("Path 50", 26, 1, 1, 1, 1)],
    [new RouteEntry("Path 51", 4, 1, 3, 1, 3), new RouteEntry("Path 52", 7, 1, 1, 1, 2)],
    [new RouteEntry("Path 53", 21, 1, 1, 3, 1), new RouteEntry("Path 54", 24, 3, 3, 3, 2)],
    [new RouteEntry("Path 55", 7, 2, 3, 3, 1), new RouteEntry("Path 56", 12, 1, 3, 3, 1)],
    [new RouteEntry("Path 57", 27, 2, 1, 3, 2), new RouteEntry("Path 58", 25, 3, 3, 3, 3)],
    [new RouteEntry("Path 59", 16, 2, 1, 3, 3), new RouteEntry("Path 60", 20, 1, 3, 1, 2)],
];

const tables: string[][][] = [
    // Page 1
    [
        ["Path 1", "Path 2"],
        ["3", "5"],
        ["Moderate", "Good"],
        ["Low", "High"],
        ["High", "High"],
        ["Hot", "Warm"]
    ],
    // Page 2
    [
        ["Path 3", "Path 4"],
        ["4", "7"],
        ["Moderate", "Moderate"],
        ["High", "Low"],
        ["Low", "Low"],
        ["Hot", "Neutral"]
    ],
    // Page 3
    [
        ["Path 5", "Path 6"],
        ["6", "7"],
        ["Good", "Good"],
        ["Low", "Low"],
        ["Low", "High"],
        ["Neutral", "Hot"],
    ],
    // Page 4
    [
        ["Path 7", "Path 8"],
        ["8", "12"],
        ["Good", "Moderate"],
        ["High", "Low"],
        ["Low", "High"],
        ["Warm", "Neutral"]
    ],
    // Page 5
    [
        ["Path 9", "Path 10"],
        ["10", "14"],
        ["Bad", "Good"],
        ["Low", "Low"],
        ["Low", "Low"],
        ["Hot", "Warm"]
    ],
    // Page 6
    [
        ["Path 11", "Path 12"],
        ["10", "17"],
        ["Bad", "Good"],
        ["Low", "Low"],
        ["Low", "High"],
        ["Hot", "Neutral"]
    ],
    // Page 7
    [
        ["Path 13", "Path 14"],
        ["11", "19"],
        ["Bad", "Good"],
        ["Low", "Low"],
        ["Low", "High"],
        ["Warm", "Warm"]
    ],
    // Page 8
    [
        ["Path 15", "Path 16"],
        ["13", "16"],
        ["Bad", "Moderate"],
        ["High", "High"],
        ["High", "High"],
        ["Hot", "Warm"]
    ],
    // Page 9
    [
        ["Path 17", "Path 18"],
        ["15", "18"],
        ["Good", "Bad"],
        ["High", "High"],
        ["Low", "High"],
        ["Warm", "Warm"]
    ],
    // Page 10
    [
        ["Path 19", "Path 20"],
        ["20", "22"],
        ["Bad", "Good"],
        ["Low", "High"],
        ["High", "High"],
        ["Neutral", "Warm"]
    ],
    // Page 11
    [
        ["Path 21", "Path 22"],
        ["21", "28"],
        ["Bad", "Good"],
        ["High", "High"],
        ["High", "High"],
        ["Hot", "Neutral"]
    ],
    // Page 12
    [
        ["Path 23", "Path 24"],
        ["23", "29"],
        ["Bad", "Moderate"],
        ["Low", "High"],
        ["High", "Low"],
        ["Hot", "Warm"]
    ],
    // Page 13
    [
        ["Path 25", "Path 26"],
        ["24", "27"],
        ["Moderate", "Bad"],
        ["Low", "Low"],
        ["Low", "Low"],
        ["Warm", "Neutral"]
    ],
    // Page 14
    [
        ["Path 27", "Path 28"],
        ["25", "30"],
        ["Moderate", "Bad"],
        ["High", "High"],
        ["Low", "Low"],
        ["Hot", "Neutral"]
    ],
    // Page 15
    [
        ["Path 29", "Path 30"],
        ["26", "29"],
        ["Moderate", "Moderate"],
        ["High", "High"],
        ["Low", "High"],
        ["Hot", "Neutral"]
    ]
];


export { RouteEntry, tables_v1, tables_v2, tables_v3, tables_v4};
