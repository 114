import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  steps: string[];
  currentStep: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStep }) => {
  return (
    <div className="progress-container">
      {steps.map((step, index) => (
        <div key={index} className="step-wrapper">
          <div className="step-container">
            <div className={`step ${currentStep >= index + 1 ? 'completed' : ''}`}></div>
          </div>
          <div className="step-label">
            {step}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
