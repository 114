import "./RegistrationPage.css";
import { useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import { useGlobalContext } from "../GlobalContext";

function RegistrationPage(props: {
    back: () => void;
    next: () => void;
    setResponse: (name: string, age: string, birthdate: string) => void;
}) {
    const [name, setName] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [birthdate, setBirthdate] = useState<string>("");
    const { isSpanish } = useGlobalContext();

    const genderOptions = ["Male", "Female", "Other"];
    const onGenderSelect = (option: any) => {
        setGender(option.value);
    };

    return (
        <div
            id=""
            className="landing-container"
            style={{ alignItems: "flex-start" }}
        >
            <div className="top-right-button">
                <SpanishButton />
            </div>

            <div style={{ marginLeft: "7%" }}>
                {!isSpanish ? (<>
                    <h1>Registration</h1>
                    <p>To start the survey, please enter the following information.</p>

                    <h4>Name</h4>
                    <input
                        maxLength={256}
                        type="text"
                        placeholder="Click here to enter"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        style={{ width: "85%" }}
                    />

                    <h4>Birthdate</h4>
                    <input
                        maxLength={10}
                        placeholder="MM/DD/YYYY"
                        onChange={(e) => setBirthdate(e.target.value)}
                        value={birthdate}
                    />

                    <h4>Gender</h4>
                    <Dropdown
                        className="dropdown"
                        options={genderOptions}
                        controlClassName="dropdown-control"
                        arrowClassName="dropdown-arrow"
                        onChange={onGenderSelect}
                        value={""}
                        placeholder="Select an option"
                    />

                    <br />
                    <br />

                </>) : (<>

                    <h1>Registro</h1>
                    <p>Para iniciar la encuesta, por favor ingrese la siguiente información.</p>

                    <h4>Nombre y apellido</h4>
                    <input
                        maxLength={256}
                        type="text"
                        placeholder="Haga clic aquí para introducir"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        style={{ width: "85%" }}
                    />

                    <h4>Fecha de nacimiento</h4>
                    <input
                        maxLength={10}
                        placeholder="MM/DD/YYYY"
                        onChange={(e) => setBirthdate(e.target.value)}
                        value={birthdate}
                    />

                    <h4>Género</h4>
                    <Dropdown
                        className="dropdown"
                        options={genderOptions}
                        controlClassName="dropdown-control"
                        arrowClassName="dropdown-arrow"
                        onChange={onGenderSelect}
                        value={"Seleccione una opción (Hombre, Mujer, Prefiere no decir)"}
                    />

                    <br />
                    <br />

                </>)}
            </div>

            <div className="bottom-left-button">
                <BackButton buttonAction={props.back} />
            </div>
            <div className="bottom-right-button">
                <NextButton
                    buttonAction={() => {
                        props.setResponse(name, gender, birthdate);
                        props.next();
                    }}
                    disable={name === "" || gender == "" || birthdate === ""}
                />
            </div>
        </div>
    );
}

export default RegistrationPage;
