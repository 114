import { SidewalkConditionSummary } from "../Education/SidewalkCondition";
import { CrimeSafetySummary } from "../Education/CrimeSafety";
import { TrafficSafetySummary } from "../Education/TrafficSafety";
import { ThermalComfortSummary } from "../Education/ThermalComfort";
import Slider from "react-slick";
import { useState, useRef, useEffect } from "react";
import "./HelpPopup.css";

function HelpPopup({ setDisplayHelpButton }) {
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(slideIndex);
  }, [slideIndex]);

  const nextPage = () => {
    setSlideIndex(slideIndex + 1);
  };

  const backPage = () => {
    setSlideIndex(slideIndex - 1);
  };

  const closeHelpPopup = () => {
    setDisplayHelpButton(false);
  };

  var sliderSettings = {
    swipe: false,
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    speed: 400,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  return (
    <>
      <Slider
        {...sliderSettings}
        ref={(slider) => {
          sliderRef.current = slider;
        }}
      >
        <SidewalkConditionSummary
          next={nextPage}
          closeHandler={closeHelpPopup}
        />
        <CrimeSafetySummary
          next={nextPage}
          back={backPage}
          closeHandler={closeHelpPopup}
        />
        <TrafficSafetySummary
          next={nextPage}
          back={backPage}
          closeHandler={closeHelpPopup}
        />
        <ThermalComfortSummary back={backPage} closeHandler={closeHelpPopup} />
      </Slider>
    </>
  );
}

export default HelpPopup;
